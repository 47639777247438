import request from '@/api/service'

const api = {
  // 查询素材分类列表
  getMaterialClassify: '/event/materialClassify/getList',
  // 创建分类
  createMaterialClassify: '/event/materialClassify/create',
  // 编辑分类
  modifyMaterialClassify:'/event/materialClassify/modify',
  // 删除分类
  removeMaterialClassify:'/event/materialClassify/remove',
  // 查询素材
  getMaterialContent:'/event/materialContent/getPage',
  // 创建素材
  createMaterialContent:'/event/materialContent/create',
  // 编辑素材
  modifyMaterialContent:'/event/materialContent/modify',
  // 删除素材
  removeMaterialContent:'/event/materialContent/remove',
  // 修改素材的分类
  modifyClassify:'/event/materialContent/modifyClassify'
}

export function getMaterialClassify(data) {
  return request({
    url: api.getMaterialClassify,
    method: 'POST',
    data,
  })
}

export function createMaterialClassify(data) {
  return request({
    url: api.createMaterialClassify,
    method: 'POST',
    data,
  })
}

export function modifyMaterialClassify(data) {
  return request({
    url: api.modifyMaterialClassify,
    method: 'POST',
    data,
  })
}

export function removeMaterialClassify(data) {
  return request({
    url: api.removeMaterialClassify,
    method: 'POST',
    data,
  })
}

export function getMaterialContent(data) {
  return request({
    url: api.getMaterialContent,
    method: 'POST',
    data,
  })
}

export function createMaterialContent(data) {
  return request({
    url: api.createMaterialContent,
    method: 'POST',
    data,
  })
}

export function modifyMaterialContent(data) {
  return request({
    url: api.modifyMaterialContent,
    method: 'POST',
    data,
  })
}

export function removeMaterialContent(data) {
  return request({
    url: api.removeMaterialContent,
    method: 'POST',
    data,
  })
}

export function modifyClassify(data) {
  return request({
    url: api.modifyClassify,
    method: 'POST',
    data,
  })
}
