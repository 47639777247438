<!-- 素材中心 -->
<template>
  <section class="cont materialCenter">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box">
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="文字" name="text"></el-tab-pane>
        <el-tab-pane label="图片" name="photo"></el-tab-pane>
        <el-row type="flex">
          <el-col :span="3" class="tree-operate">
            <el-input v-model="filterText" placeholder="搜索文件夹名称" prefix-icon="el-icon-search" class="title-search"></el-input>
            <el-button round icon="el-icon-plus" class="btn" @click="createFile">新建文件夹</el-button>
            <el-tree :data="treeData" :props="defaultProps" @node-click="handleNodeClick" class="file-tree-list" :expand-on-click-node="false" highlight-current :filter-node-method="filterNode" ref="tree">
              <span slot-scope="{ node, data }">
                <span>
                  <i class="el-icon-folder-opened">{{ node.label }}</i>
                  <span class="text-count">({{ data.count || 0 }})</span>
                </span>
                <span class="custom-tree-node" :style="active.id === data.id ? 'display: block' : ''">
                  <el-button type="text" size="mini" @click="($event) => addPositionData($event, data)">
                    <i class="el-icon-more"></i>
                  </el-button>
                </span>
              </span>
            </el-tree>
          </el-col>
          <el-col :span="20" class="material-main">
            <div class="material-title">
              <div class="title-text">
                <span class="text-name">{{ active.name }}</span>
                <span class="text-count">({{ total }})</span>
                <el-tooltip effect="dark" placement="right">
                  <i class="el-icon-question"></i>
                  <ul slot="content">
                    <li><span>1、素材支持应用到欢迎语、群发消息、维护企业朋友圈、聊天工具栏等功能。</span></li>
                    <li><span>2、仅展示当前选中文件夹的素材</span></li>
                  </ul>
                </el-tooltip>
              </div>
              <el-button type="primary" round icon="el-icon-plus" class="text-creation" :disabled="!treeData.length" @click="create">创建素材</el-button>
              <el-input v-model="title" :placeholder="activeName === 'text' ? '搜索关键字' : '搜索图片名称'" prefix-icon="el-icon-search" class="title-search"></el-input>
            </div>
            <div class="material-operate">
              <div class="block-btn">
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
              </div>
              <el-button round size="medium" style="margin-right: 6px; width: 102px" :disabled="!checkedCities.length" @click="move">移动到</el-button>
              <el-button round size="medium" style="width: 102px" :disabled="!checkedCities.length" @click="DeleteMenu('all')">删 除</el-button>
            </div>
            <div class="material-container" v-loading="loading">
              <div class="container-inner" v-if="materialData.length">
                <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange" style="width: 100%">
                  <el-checkbox v-for="item in materialData" :label="item" :key="item.id" class="material-item">
                    <div class="material-card-wrapper">
                      <div class="card-operate" :class="activeName === 'text' ? '' : 'card-operate2'" @click.prevent="addPositionData($event, item)">
                        <i class="el-icon-more"></i>
                      </div>
                      <div class="text-card" v-if="activeName === 'text'">
                        <p class="text-ellipis-2">{{ item.title }}</p>
                      </div>
                      <div class="card-content" v-else>
                        <div class="image-bg">
                          <img :src="item.filePath" alt="" width="100%" height="162" />
                          <div class="image-size">300 X 300</div>
                        </div>
                        <div class="image-title">{{ item.title }}.{{ item.fileType }}</div>
                      </div>
                    </div>
                  </el-checkbox>
                </el-checkbox-group>
              </div>
              <el-empty :image="require('@/assets/group_empty.png')" v-else></el-empty>
            </div>
            <!-- 分页 -->
            <pagination :total="total" :currentPage="page" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging" />
          </el-col>
        </el-row>
      </el-tabs>
    </el-row>
    <!-- 点击显示 menu -->
    <vue-context-menu class="context-menu" :contextMenuData="contextMenuData" @EditMenu="EditMenu" @DeleteMenu="DeleteMenu"></vue-context-menu>
    <!-- 编辑文件夹名称 -->
    <el-dialog :title="dialogState === 'create' ? '新建文件夹' : '编辑文件夹名称'" :visible.sync="show" width="600px">
      <el-input type="text" placeholder="请填写" v-model="fileName" maxlength="6" show-word-limit v-if="dialogState !== 'create'"></el-input>
      <template v-else>
        <div class="text">所属上级文件夹</div>
        <el-select v-model="treeFile" placeholder="请选择" style="width: 560px; margin-bottom: 24px">
          <el-option label="默认" value="0"></el-option>
          <el-option :label="item.name" :value="item.id" v-for="item in treeData" :key="item.id"></el-option>
        </el-select>
        <div class="text">文件夹名称</div>
        <el-input type="text" placeholder="请填写" v-model="fileName" maxlength="6" show-word-limit></el-input>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button type="primary" @click="saveFile">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 移动 -->
    <el-dialog title="移动到" :visible.sync="moveToShow" width="600px">
      <div class="box">
        <div class="move">级联选择</div>
        <el-cascader v-model="moveTo" :options="treeData" :props="{ checkStrictly: true }" style="width: 480px"></el-cascader>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="moveToShow = false">取 消</el-button>
        <el-button type="primary" @click="modifyClassify">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 右侧弹窗 编辑素材 -->
    <el-drawer :title="drawerState === 'create' ? '创建素材' : '编辑素材'" :visible.sync="drawer" direction="rtl" custom-class="demo-drawer" ref="drawer" :size="460">
      <div class="demo-drawer__content">
        <div class="text">{{ activeName === 'text' ? '文字素材' : '图片素材' }}</div>
        <el-input type="textarea" placeholder="请填写" v-model="materialContent" maxlength="500" rows="8" show-word-limit v-if="activeName === 'text'"></el-input>
        <div v-else>
          <i class="el-icon-plus" v-if="!url" @click="visibleImage = true"></i>
          <div class="img-upload" v-else>
            <img class="file" :src="url" alt="" />
            <i class="el-icon-refresh" @click="visibleImage = true"></i>
          </div>
          <!-- <div class="tips">注：图片最大限制2MB内</div> -->
          <div class="box">
            <div class="name">图片名称</div>
            <el-input v-model="fileName" placeholder="请填写" style="width: 250px"></el-input>
            <div>&nbsp;.{{ contextMenuData.menuData.fileType || 'jpg' }}</div>
          </div>
        </div>
        <div class="demo-drawer__footer footer">
          <el-button @click="drawer = false">取 消</el-button>
          <el-button type="primary" :disabled="activeName === 'photo' && !url" @click="save" :loading="creating">{{ drawerState === 'create' ? '创 建' : '保 存' }}</el-button>
        </div>
      </div>
    </el-drawer>
    <!-- 图片上传 -->
    <Cropper :visible="visibleImage" @setImageInfo="getImageInfo" @closeUploadImg="closeUploadImg"></Cropper>
  </section>
</template>

<script>
  import { getMaterialClassify, createMaterialClassify, modifyMaterialClassify, removeMaterialClassify, getMaterialContent, createMaterialContent, 
    modifyMaterialContent, removeMaterialContent, modifyClassify } from '@/api/smtech/materialCenter'
  import Cropper from '@/components/local/Cropper'
  import { mapState } from 'vuex'
  export default {
    name: 'materialCenter',
    components: { Cropper },
    data() {
      return {
        crumbs: new Map([['营销云'], ['素材中心']]),
        activeName: 'text',
        active: {},
        visibleImage: false,
        url: '', // 图片地址
        file: null,
        loading: true,
        creating:false,

        treeData: [],
        materialData: [],
        defaultProps: {
          children: 'children',
          label: 'name',
        },
        filterText: '',
        title: '',

        /** 全选 */
        checkAll: false,
        checkedCities: [],
        isIndeterminate: false,
        data: [],

        /** menu */
        contextMenuData: {
          menuName: 'demo',
          axis: { x: null, y: null },
          // fnHandler绑定事件  btnName菜单名称
          menulists: [
            { fnHandler: 'EditMenu', btnName: '编辑' },
            { fnHandler: 'DeleteMenu', btnName: '删除' },
          ],
          menuData: {},
        },

        /** 编辑文件夹名称 */
        show: false,
        treeFile: '',
        fileName: '',
        dialogState: 'create',
        drawer: false,
        materialContent: '',
        drawerState: 'create',
        moveToShow: false,
        moveTo: [],

        /** 分页 */
        total: 1,   // 用户列表总条目数
        page: 1,    // 当前页 默认第一页
        limit: 10,  // 每页显示数

        prompt: '提示！',
        del_success: '删除成功！',
      }
    },
    computed: {
      ...mapState(['hotelInfo']),
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val)
      },
      title() {
        this.page = 1
        this.getData()
      },
    },
    mounted() {
      this.getClassifyData()
    },
    methods: {
      // 文件夹列表
      async getClassifyData() {
        let data = new FormData()
        data.append('type', this.activeName === 'text' ? 'VERBAL_TRICK' : 'IMG')
        data.append('hotelId', this.hotelInfo.id)
        data.append('companyId', this.hotelInfo.storeId)
        await getMaterialClassify(data).then(({ success, records }) => {
          if (success) {
            let obj = []
            for (let i = 0; i < records.length; i++) {
              records[i].value = records[i].id
              records[i].label = records[i].name
              if (records[i].parentId === undefined) {
                obj.push(records[i])
              }
            }
            for (let i = 0; i < records.length; i++) {
              if (records[i].parentId !== undefined) {
                delete records[i].children
                for (let j = 0; j < obj.length; j++) {
                  if (records[i].parentId === obj[j].id) {
                    obj[j].children.push(records[i])
                  }
                }
              }
            }
            for (let i = 0; i < obj.length; i++) {
              if (!obj[i].children.length) {
                delete obj[i].children
              }
            }
            this.treeData = obj
            // 默认第一条
            this.active = { ...obj[0] }
          }
        })
        await this.getData()
      },
      // 素材列表
      getData() {
        let data = new FormData()
        data.append('type', this.activeName === 'text' ? 'VERBAL_TRICK' : 'IMG')
        data.append('page', this.page)
        data.append('limit', this.limit)
        data.append('classifyId', this.active.id)
        if (this.title) {
          data.append('title', this.title)
        }
        getMaterialContent(data).then(({ success, records, total }) => {
          if (success) {
            this.materialData = records
            this.total = total
            this.data = records
            this.loading = false
          }
        })
      },
      //  切换导航栏
      handleClick() {
        this.checkedCities = []
        this.checkAll = false
        this.isIndeterminate = false
        this.loading = true
        this.getClassifyData()
      },
      /** 节点 */
      handleNodeClick(data) {
        this.active = { ...data }
        this.getData()
      },
      filterNode(value, data) {
        if (!value) return true
        return data.name.indexOf(value) !== -1
      },
      // 移动
      move() {
        this.moveToShow = true
        this.moveTo = []
      },
      // 确定移动
      modifyClassify() {
        let data = new FormData()
        let ids = []
        for (let i = 0; i < this.checkedCities.length; i++) {
          ids.push(this.checkedCities[i].id)
        }
        data.append('ids', ids)
        data.append('classifyId', this.moveTo[this.moveTo.length - 1])
        modifyClassify(data).then(({ success }) => {
          if (success) {
            this.moveToShow = false
            this.$message({ showClose: true, message: '操作成功!', type: 'success' })
            this.getClassifyData()
            this.isIndeterminate = false
            this.checkedCities = []
          }
        })
      },
      /** 全选 */
      handleCheckAllChange(val) {
        this.checkedCities = val ? this.data : []
        this.isIndeterminate = false
      },
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length
        this.checkAll = checkedCount === this.data.length
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.data.length
      },
      /** menu */
      addPositionData(e, item) {
        // 菜单坐标跟随
        let positionData = e.path[0].getBoundingClientRect()
        let x = positionData.x - 70
        let y = positionData.y + positionData.height + 5
        this.contextMenuData.axis = { x, y }
        this.contextMenuData.menuData = item
      },
      // 编辑
      EditMenu() {
        if (this.contextMenuData.menuData.name) {
          this.show = true
          this.fileName = this.contextMenuData.menuData.name
          this.dialogState = 'edit'
        } else {
          this.drawer = true
          this.materialContent = this.contextMenuData.menuData.title
          this.url = this.contextMenuData.menuData.filePath
          this.fileName = this.contextMenuData.menuData.title
          this.drawerState = 'edit'
        }
      },
      // 创建文件夹
      createFile() {
        this.show = true
        this.treeFile = '0'
        this.fileName = ''
        this.dialogState = 'create'
      },
      // 创建/编辑 分类
      saveFile() {
        if (this.dialogState === 'create') {
          let data = new FormData()
          data.append('type', this.activeName === 'text' ? 'VERBAL_TRICK' : 'IMG')
          data.append('name', this.fileName)
          data.append('hotelId', this.hotelInfo.id)
          data.append('companyId', this.hotelInfo.storeId)
          if (this.treeFile !== '0') {
            data.append('parentId', this.treeFile)
          }
          createMaterialClassify(data).then(({ success }) => {
            if (success) {
              this.show = false
              this.$message({ showClose: true, message: '创建成功!', type: 'success' })
              this.getClassifyData()
            }
          })
        } else {
          let data = new FormData()
          data.append('id', this.contextMenuData.menuData.id)
          data.append('name', this.fileName)
          modifyMaterialClassify(data).then(({ success }) => {
            if (success) {
              this.show = false
              this.$message({ showClose: true, message: '编辑成功!', type: 'success' })
              this.getClassifyData()
            }
          })
        }
      },
      // 创建素材
      create() {
        this.drawer = true
        this.drawerState = 'create'
        this.materialContent = ''
        this.url = ''
        this.fileName = ''
      },
      // 创建/编辑 素材
      save() {
        this.creating = true
        if (this.drawerState === 'create') {
          let data = new FormData()
          data.append('type', this.activeName === 'text' ? 'VERBAL_TRICK' : 'IMG')
          data.append('title', this.activeName === 'text' ? this.materialContent : this.fileName)
          data.append('classifyId', this.active.id)
          data.append('hotelId', this.hotelInfo.id)
          data.append('companyId', this.hotelInfo.storeId)
          if (this.activeName !== 'text') {
            data.append('file', this.file)
            data.append('ossFileParam.module', 'material')
            data.append('ossFileParam.func', 'image')
            data.append('ossFileParam.category', 'media')
          }
          createMaterialContent(data).then(({ success }) => {
            if (success) {
              this.creating = false
              this.drawer = false
              this.$message({ showClose: true, message: '创建成功!', type: 'success' })
              this.getData()
            }
          })
        } else {
          let data = new FormData()
          data.append('id', this.contextMenuData.menuData.id)
          data.append('title', this.activeName === 'text' ? this.materialContent : this.fileName)
          if (this.activeName !== 'text' && this.file) {
            data.append('file', this.file)
            data.append('ossFileParam.module', 'material')
            data.append('ossFileParam.func', 'image')
            data.append('ossFileParam.category', 'media')
          }
          modifyMaterialContent(data).then(({ success }) => {
            if (success) {
              this.creating = false
              this.drawer = false
              this.$message({ showClose: true, message: '编辑成功!', type: 'success' })
              this.getData()
            }
          })
        }
      },
      // 关闭上传图片会话框
      closeUploadImg() {
        this.visibleImage = false
      },
      // 获取上传图片信息
      getImageInfo({ url }, blobInf, _, file) {
        this.visibleImage = false
        this.url = url
        this.file = file
      },
      // 删除文件夹
      remove() {
        this.$confirm('是否删除此文件夹下的所有素材？', this.prompt, {
          confirmButtonText: this.confirm,
          cancelButtonText: this.cancel,
          type: 'warning',
        }).then(() => {
          if (this.contextMenuData.menuData.children) {
            this.$message({ showClose: true, message: '此文件夹下有文档,删除失败!', type: 'error' })
            return
          }
          let data = new FormData()
          data.append('id', this.contextMenuData.menuData.id)
          removeMaterialClassify(data).then(({ success }) => {
            if (success) {
              this.$message({ showClose: true, message: this.del_success, type: 'success' })
              this.getClassifyData()
            }
          })
        })
      },
      // 删除
      DeleteMenu(str) {
        if (this.contextMenuData.menuData.name) {
          this.remove()
        } else {
          this.$confirm('删除后，在素材中心将无法选择到该素材。是否删除？', this.prompt, {
            confirmButtonText: this.confirm,
            cancelButtonText: this.cancel,
            type: 'warning',
          }).then(() => {
            let data = new FormData()
            if (str === 'all') {
              let ids = []
              for (let i = 0; i < this.checkedCities.length; i++) {
                ids.push(this.checkedCities[i].id)
              }
              data.append('ids', ids)
            } else {
              data.append('ids', this.contextMenuData.menuData.id)
            }
            removeMaterialContent(data).then(({ success }) => {
              if (success) {
                this.drawer = false
                this.$message({ showClose: true, message: this.del_success, type: 'success' })
                this.getData()
                this.isIndeterminate = false
                this.checkedCities = []
              }
            })
          })
        }
      },

      // 改变每页数
      pageChange(num) {
        this.limit = num
        this.getData()
      },
      // 改变当前页
      handlePaging(num) {
        this.page = num
        this.getData()
      },
    },
  }
</script>

<style scoped lang="scss">
  .materialCenter {
    .tree-operate {
      width: 224px;
      box-shadow: 5px 0px 5px -5px #ccc;
      padding: 16px;
      padding-bottom: 0;
      .title-search {
        margin-bottom: 16px;
        /deep/ .el-input__inner {
          border-radius: 20px;
        }
      }
      .btn {
        width: 192px;
      }
      .file-tree-list {
        font-size: 14px;
        margin-top: 16px;
        border-top: 1px solid #eee;
        .text-count {
          font-size: 12px;
          color: #999;
          margin: 0 5px;
        }
        /deep/ .el-tree-node__content {
          position: relative;
          .custom-tree-node {
            display: none;
            position: absolute;
            right: 0;
            top: -1px;
            .el-icon-more {
              transform: rotate(90deg);
              color: #c4c4c4;
              &:hover {
                color: #678ff4;
              }
            }
          }
          &:hover .custom-tree-node {
            display: block;
          }
        }
      }
    }
    .material-main {
      padding-left: 24px;
      .material-title {
        padding: 16px 0;
        .title-text {
          height: 40px;
          display: inline-block;
          line-height: 40px;
          font-size: 13px;
          .text-name {
            color: #333;
            font-weight: bold;
          }
          .text-count {
            color: #999;
            margin: 0 5px;
          }
          .el-icon-question {
            cursor: pointer;
          }
        }
        .text-creation {
          float: right;
          margin-left: 16px;
        }
        .title-search {
          width: 220px;
          float: right;
          /deep/ .el-input__inner {
            border-radius: 20px;
          }
        }
      }
      .material-operate {
        .block-btn {
          width: 100px;
          display: inline-block;
          margin-right: 16px;
          text-align: center;
          line-height: 34px;
          font-size: 13px;
          border: 1px solid #e0e0e0;
          border-radius: 18px;
          cursor: pointer;
        }
      }
      .material-container {
        margin: 8px -8px 0;
        .container-inner {
          display: flex;
          flex-wrap: wrap;
          .material-item {
            width: 18%;
            padding: 8px;
            position: relative;
            margin: 0;
            /deep/ .el-checkbox__input {
              position: absolute;
              top: 18px;
              left: 32px;
              z-index: 2;
            }
            /deep/ .el-checkbox__label {
              width: 100%;
            }
            .material-card-wrapper {
              border: 1px solid #dfdfdf;
              border-radius: 4px;
              overflow: hidden;
              .card-operate {
                transform: rotate(90deg);
                position: absolute;
                right: 12px;
                top: 16px;
                z-index: 2;
                .el-icon-more {
                  color: #c4c4c4;
                  &:hover {
                    color: #678ff4;
                  }
                }
              }
              .card-operate2 {
                .el-icon-more {
                  background: rgba(0, 0, 0, 0.6);
                  &:hover {
                    color: #fff;
                    background-color: #409fff;
                  }
                }
              }
              .text-card {
                height: 80px;
                font-size: 14px;
                box-sizing: border-box;
                padding: 33px 16px 0;
              }
              .text-ellipis-2 {
                overflow: hidden;
                white-space: pre-wrap;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
              }
              &:hover {
                border-color: #678ff4;
                .card-content {
                  .image-bg {
                    .image-size {
                      display: block;
                    }
                  }
                  .image-title {
                    background: #eff3fd;
                  }
                }
              }
              .card-content {
                width: 100%;
                .image-bg {
                  position: relative;
                  .image-size {
                    width: 100%;
                    display: none;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background: rgba(0, 0, 0, 0.6);
                    height: 24px;
                    line-height: 22px;
                    text-align: center;
                    color: #fff;
                    font-size: 12px;
                  }
                  img {
                    display: block;
                  }
                }
                .image-title {
                  font-size: 13px;
                  height: 30px;
                  line-height: 30px;
                  padding: 0 10px;
                  text-align: center;
                  word-break: break-all;
                }
              }
            }
          }
        }
      }
    }
    .context-menu {
      border: 1px solid #ddd;
    }
    /deep/ .el-dialog__body {
      padding-top: 24px;
      .text {
        color: #999;
        margin-bottom: 10px;
      }
      .box {
        display: flex;
        .move {
          width: 65px;
          height: 40px;
          line-height: 40px;
          padding-right: 16px;
          color: #a3a3a3;
        }
      }
    }
    /deep/ .el-drawer__header {
      margin-bottom: 24px;
    }
    /deep/ .el-drawer__body {
      padding: 0 20px;
      .text {
        font-size: 14px;
        margin-bottom: 10px;
      }
      .el-icon-plus {
        font-size: 25px;
        color: #888;
        padding: 18px;
        border: 1px dashed #cfd7ea;
        background-color: #f5fbff;
        border-radius: 4px;
        cursor: pointer;
      }
      .img-upload {
        position: relative;
        .file {
          width: 125px;
          height: 95px;
        }
        .el-icon-refresh {
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 125px;
          height: 95px;
          color: white;
          font-size: 24px;
          text-align: center;
          line-height: 95px;
          background: rgba(0, 0, 0, 0.2);
          cursor: pointer;
        }
        &:hover .el-icon-refresh {
          display: block;
        }
      }
      .tips {
        margin-top: 10px;
        font-size: 14px;
        color: #999;
      }
      .box {
        display: flex;
        margin-top: 20px;
        height: 40px;
        font-size: 14px;
        line-height: 40px;
        color: #a3a3a3;
        .name {
          width: 65px;
          padding-right: 16px;
        }
      }
      .footer {
        position: absolute;
        bottom: 20px;
        right: 20px;
      }
    }
  }
</style>
